import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter, ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import useGetColors from 'shared/hooks/useGetColors';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CodeValuesT } from '../../api/types/managers';
import TextField from '../../components/form/TextField';
import { codeFormResolver, CodeFormT, getCodeValues, SignInSendCodeFormT } from '../../form-values/signInSendCode';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useConfirmCode } from '../../api/requests/managers';
import { fillFormErrors } from '../../utils/devUtils';

interface Props {
  mainData: SignInSendCodeFormT;
  isOpen: boolean;
  onClose: VoidFunction;
}

const CodeModal = ({ mainData, isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const { gray800, gray100 } = useGetColors();
  const { mutate, isPending } = useConfirmCode();
  const { white, brand500, brand400 } = useGetColors();
  const methods = useForm<CodeValuesT>({
    defaultValues: getCodeValues(),
    resolver: codeFormResolver,
    mode: "onChange"
  });
  const { formState: { isValid } } = methods;

  const onSubmit: SubmitHandler<CodeValuesT> = (values) => {
    const mergedValues = { ...mainData, ...values };
    mutate({
      ...mergedValues,
      apiId: Number(mergedValues.apiId),
    }, {
      onError: (error: FixMeLater) => {
        if (error.response && error.response.data) {
          const { message } = error.response.data;
          if (message.includes('PHONE_CODE_INVALID')) {
            methods.setError('code', {
              type: 'manual',
              message: t('validation.invalidCode'),
            });
          }
          else {
            fillFormErrors(methods.setError, error);
          }
        } else {
          fillFormErrors(methods.setError, error);
        }
      },
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>{t('managers.enterTgCode')}</ModalHeader>
        <ModalBody display="flex" flexDirection="column" alignItems="center">
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)} style={{ width: '100%' }}>
              <TextField<CodeFormT>
                name="code"
                label={`${t('forms.code')} *`}
                defaultValue=""
                placeholder={t('forms.enterCode')}
                mb="20px"
              />
              <Button
                color={white}
                borderRadius="8px"
                _hover={{
                  bgColor: brand400,
                }}
                bgColor={brand500}
                isLoading={isPending}
                w="100px"
                type="submit"
                mr="20px"
                isDisabled={!isValid}
              >
                {t('buttons.next')}
              </Button>
              <Button
                color={gray800}
                bgColor={gray100}
                borderRadius="8px"
                onClick={onClose}
                w="100px"
              >
                {t('buttons.cancel')}
              </Button>
            </form>
          </FormProvider>
        </ModalBody>
        <ModalFooter>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CodeModal;
