import React from 'react';
import AsyncMultiSelectField from './AsyncMultiSelectField';
import AsyncSelectField from './AsyncSelectField';
import DateField from './DateField';
import RangeDateField from './RangeDateField';
import SelectField from './SelectField';
import TextField from './TextField';
import { FilterField } from 'shared/types/filters';

type Props = FilterField;

const Field = ({
  type,
  placeholder,
  name,
  label,
  size,
  fullWidth,
  ...props
}: Props) => {
  const commonFieldProps = {
    label,
    name,
    placeholder,
    size,
    fullWidth
  };
  switch (type) {
    case 'text':
      return <TextField {...commonFieldProps} />;

    case 'select': {
      const selectProps = props as FilterField<'select'>;
      return <SelectField {...commonFieldProps} options={selectProps.options} onChange={props.onChange} />;
    }

    case 'date':
      return <DateField {...commonFieldProps} />;

    case 'date-range':
      if (!Array.isArray(props.defaultValue)) {
        throw new TypeError("Default value must be an array");
      }

      return <RangeDateField {...commonFieldProps} />;

    case 'async-select': {
      const selectProps = props as FilterField<'async-select'>;
      const selectCommonProps = {
        ...commonFieldProps,
        onChange: props.onChange,
        optionsQuery: selectProps.optionsQuery,
        valueName: selectProps.valueName as FixMeLater,
        labelName: selectProps.labelName as FixMeLater
      };

      if (selectProps.isMulti) {
        if (!Array.isArray(props.defaultValue)) {
          throw new TypeError("Default value must be an array");
        }
        return <AsyncMultiSelectField fullWidth={fullWidth} {...selectCommonProps} />;
      }
      return <AsyncSelectField fullWidth={fullWidth} {...selectCommonProps} />;
    }

    default:
      return <TextField {...commonFieldProps} />;
  }
};

export default Field;
