import { FormControl, FormLabel } from '@chakra-ui/react';
import { Select } from "chakra-react-select";
import { ListQueryRequestType } from 'shared/api/types/typedQueries';
import { FieldProps } from 'shared/types/filters';
import useSelectField from './hooks/useSelectField';
import useIsInvalid from './hooks/useIsInvalid';
import ErrorMessage from './ErrorMessage';
import {chakraStyles} from './AsyncMultiSelectField'

const AsyncSelectField = <O extends ListQueryRequestType>({
  label,
  size = 'md',
  fullWidth = false,
  ...props
}: FieldProps<'async-select', 'input', O>) => {
  const selectProps = useSelectField<O>(props);
  const isInvalid = useIsInvalid(props.name);

  // console.log('AsyncSelectField render');
  // console.log('props', props);

  return (
    <FormControl
      mb={'20px'}
      w={fullWidth ? '100%' : { base: '100%', md: '50%' }}
      isInvalid={isInvalid} size={size} position="relative">
      {label && (
        <FormLabel fontSize="md" fontWeight="500" mb="8px">
          {label}
        </FormLabel>
      )}
      <Select
        {...selectProps}
        chakraStyles={chakraStyles}
        useBasicStyles
        size={size}
      // classNamePrefix={'chakra-select' + `${small ? '--small' : ''}`}
      />
      <ErrorMessage name={props.name} />
    </FormControl>
  );
};

export default AsyncSelectField;
