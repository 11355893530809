import { typedInfiniteQuery } from '../types/typedQueries';
import { useInfiniteQuery } from '@tanstack/react-query';
import { instance } from './common';
import { ResourcesT } from '../types/resources';

const BASE_URL = 'resources';

const RESOURCES_KEY = 'RESOURCES_KEY';

export const useGetResources = typedInfiniteQuery<ResourcesT>(({ params }) => (
  useInfiniteQuery({
    queryKey: [RESOURCES_KEY, params],
    queryFn: () => instance.get<ResourcesT['response']>(BASE_URL, { params }),
    initialPageParam: 1,
    getNextPageParam: () => null,
    getPreviousPageParam: () => null,
  })
));
